export default class DateFormatter {
  static displayDate(dateTime, displayYear = true) {
    if (dateTime == null){
      return "- date not set -";
    }
    var date = new Date(dateTime);
    var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    let weekCount = Math.ceil(date.getDate() / 7);
    let yearString = displayYear ? (' - ' + date.getFullYear()) : '';
    return months[date.getMonth()] + ' - Week ' + weekCount + yearString;
  }
}
