<template>
  <div class="b-tooltips">
  <b-tooltip
      multilined
      type="is-light"
      position="is-bottom"
      size="is-large">
    <editor-content :editor="editor" />
    <template v-slot:content>
      <b>Keystrokes for selected text</b>
        <ul style="text-align: left;">
          <li>Apply normal text style: <b>ctrl+alt+0</b></li>
          <li>Paste without format: <b>ctrl+shift+v</b></li>
          <li>Add link: <b>ctrl+c</b> when pasted content is a URL</li>
          <li>Remove link: <b>ctrl+x</b> then <b>ctrl+shift+v</b></li>
        </ul>
    </template>
  </b-tooltip>
  </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import TaskList from '@tiptap/extension-task-list'
import TaskItem from '@tiptap/extension-task-item'
import Placeholder from '@tiptap/extension-placeholder'
import Link from '@tiptap/extension-link'

export default {
  components: {
    EditorContent,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      editor: null,
    }
  },
  watch: {
    value(value) {
      // HTML
      const isSame = this.editor.getHTML() === value

      // JSON
      //const isSame = this.editor.getJSON().toString() === value.toString()

      if (isSame) {
        return
      }

      this.editor.commands.setContent(this.value, false)
    },
  },
  mounted() {
    this.editor = new Editor({
      extensions: [
        StarterKit,
        TaskList,
        TaskItem,
        Placeholder.configure({
          placeholder: this.placeholder
        }),
        Link.configure({
          linkOnPaste: true,
          // TODO make these look nice!
          HTMLAttributes: {
             class: 'todo',
          },
        })
      ],
      content: this.value,
      onUpdate: () => {
        // HTML
        this.$emit('input', this.editor.getHTML())

        // JSON
        //this.$emit('input', this.editor.getJSON())
      },
    })
  },

  beforeDestroy() {
    this.editor.destroy()
  },
}
</script>


<style lang="scss">

.b-tooltips{
  .b-tooltip {
        width: 100%;
    }
}

/* Basic editor styles */
.ProseMirror {
  outline: none;
  padding: 0.45em 0.75em;
  background-color: white;
  border: 1px solid transparent;
  border-color: #dbdbdb;
  border-radius: 4px;
  color: #333333;
  box-shadow: inset 0 0.0625em 0.125em rgba(10, 10, 10, 0.05);

  p.is-editor-empty:first-child::before {
    content: attr(data-placeholder);
    float: left;
    color: #adb5bd;
    pointer-events: none;
    height: 0;
  }

  > * + * {
    margin-top: 0.75em;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  ul[data-type="taskList"] {
    list-style: none;
    padding: 0;

    li {
      display: flex;
      align-items: center;

      > label {
        flex: 0 0 auto;
        margin-right: 0.5rem;
      }
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0D0D0D;
    color: #FFF;
    font-family: 'JetBrainsMono', monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  hr {
    margin: 1rem 0;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0D0D0D, 0.1);
  }
}

.ProseMirror:focus {
  border-color: #42b983;
  box-shadow: 0 0 0 0.125em rgba(66, 185, 131, 0.25);
}

</style>
